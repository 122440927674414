/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { Column, FlexRow, Row } from '../components/defaults';
import styled from 'styled-components';
import arrow from '../images/arrow-navy.svg';
import shareIcon from '../images/share.svg';
import { FinalAge } from '../components/final-age';
import bgFlareImage from '../images/gradient-flare.svg';
import { ShareBanner } from '../components/share-banner';
import { AgeImage } from '../components/age-image';
import biologicalAgeGraphBackground from "../images/biological-age-graph.svg";
import clockIcon from '../images/white-clock.svg';
import { getDifferenceString } from '../utils/formatters';
import { useIsTruMe } from '../utils/white-label';

const breakpoint = '1000px';

const ChronologicalAgeWrapper = styled(Row)`
    border-radius: 100px;
    padding: 10px 20px;
    font-size: 18px;
    gap: 8px;
    color: white;
    background: rgba(255,255,255,0.08);
    z-index: 1;

    img {
        height: 24px;
    }
`;  

const Wrapper = styled(Column)`
    background: #FDFDFF;
`; 

const GraphBackground = styled.img`
    position: absolute;
    max-width: 80%;
    left: 0px;
    z-index: 0;

    @media (max-width: ${breakpoint}) {
        top: 60px;
    }

    @media (min-width: ${breakpoint}) {
        display: none;
    }
`;


const Background = () => <GraphBackground src={biologicalAgeGraphBackground} />

const Contents = styled(Column)`
    background: rgba(10, 19, 48, 1);

    @media (min-width: ${breakpoint}) {
        padding: 40px;
    }
`;

const ResultSubtitle = styled.div`
    color: white;
    max-width: 550px;
    text-align: left;
    font-size: 19px;
    font-family: 'Satoshi-Regular';

    @media (max-width: ${breakpoint}) {
        margin-top: 15px;
        text-align: center;
    }
`;

const TopPanelWrapper = styled(Row)`
    padding: 70px;
    align-items: center;
    box-sizing: border-box;
    min-height: 90vh;


    @media (max-width: ${breakpoint}) {
        flex-direction: column;
        padding: 50px 30px;
    }
`;

const ButtonsWrapper = styled(FlexRow)`
    margin-top: 40px;
    gap: 15px;

    @media (max-width: 1100px) {
        justify-content: center;
        align-items: center;
    }
`;

const TopPanelButton = styled(Row)`
    cursor: pointer;
    white-space: nowrap;
    background: ${props => props.background ? props.background : 'transparent'};
    border: 1px solid white;
    border-radius: 100px;
    padding: 13px 24px;
    color: ${ props => props.color ? props.color : 'white'};
    cursor: pointer;
    align-items: center;

    img {
        margin-left: 10px;
        height: 15px;
    }
`; 


const LeftColumn = styled(Column)`
    flex: 1;

    @media (min-width: ${breakpoint}) {
        flex: 0.7;
    }

`;

const AgeInfoWrapper = styled.div`
    flex: 1;
    position: relative;
    align-self: stretch;

    @media (max-width: ${breakpoint}) {
        flex: initial;
        height: 180px;
    }

    @media (max-width: ${breakpoint}) {
        ${props => !props.mobile && 'display: none;'}
    }

    @media (min-width: ${breakpoint}) {
        ${props => props.mobile && 'display: none;'}
    }
`;

const ResultText = styled.div`
    color: white;
    font-family: 'Satoshi-Medium';
    font-size: 44px;
    text-align: left;
    max-width: 566px;
    margin-bottom: 31px;

    span {
        color: #1EDCFF;
    }

    @media (max-width: 762px) {
        font-size: 32px;
        text-align: center;
        width: 100%;
        align-self: center;
    }
`;

const BgFlare = styled.img`
    height: 100%;
`;

const BgWrapper = styled(Column)`
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px; 
    align-items: center;
    justify-content: center;
`;

const AgeWrapper = styled(Column)`
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    align-items: center;
    justify-content: center;
`


const ResultHeadline = ({
    years,
    months,
    lower
}) => {

    const yearString = ` ${years}` + (years === 1 ? ' year' : ' years');
    const monthString = months ? `and ${months}` + (months === 1 ? ' month ' : ' months ') : '';
    const lowerString = lower ? 'lower' : 'higher'

    return <ResultText>
        Your Predicted Biological Age is <span>{yearString} {monthString}</span>
        {lowerString} than your chronological age.
    </ResultText>
    
}

const ChronologicalAge = styled(Column)`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    align-items: center;

    @media (max-width: ${breakpoint}) {
        display: none;
    }
`;


const AgeInfo = ({ chronologicalAge, predictedAge, mobile }) => {

    return <AgeInfoWrapper mobile={mobile}>
        <AgeWrapper>
            <FinalAge age={predictedAge} />
        </AgeWrapper>
        <BgWrapper>
            <BgFlare src={bgFlareImage}/>
        </BgWrapper>
        <ChronologicalAge>
            <ChronologicalAgeWrapper>
                <img src={clockIcon} alt=''/>
                Chronological Age: {chronologicalAge}
            </ChronologicalAgeWrapper>
        </ChronologicalAge>
    </AgeInfoWrapper>

}


const TopPanel = ({
    years,
    months,
    lower,
    predictedAge,
    chronologicalAge,
}) => {

    const [shareBanner, setShareBanner] = useState(false);

    const { loading, isTrume } = useIsTruMe();
    
    const share = async () => {
        setShareBanner(true);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [shareBanner])

    const imageComp = <AgeImage 
        predictedAge
        referenceAge={predictedAge} 
        chronologicalAge={chronologicalAge} />

    return <Contents>
        <Background />
        <ShareBanner
        title={`My Predicted Biological Age is ${getDifferenceString(predictedAge, chronologicalAge)}`}
        image={imageComp}
        url="https://trumelabs.com/predict-bio-age/"
        visible={shareBanner}
        setVisible={setShareBanner}
        onClose={() => setShareBanner(false)}/>
        <TopPanelWrapper breakpoint='1100px'>
            <LeftColumn>
                <ResultHeadline {...{years, months, lower}} />
                <AgeInfo mobile {...{predictedAge, chronologicalAge}} />
                <ResultSubtitle>Discover how to take control of your biological age with TruMe Labs. Order a TruMe test today.</ResultSubtitle>
                <ButtonsWrapper>
                    <TopPanelButton onClick={() => window.open('https://trumelabs.com/biological-age-test/','_blank')} background='white' color='rgba(5, 31, 115, 1)'>Order TruMe Test<img src={arrow} /></TopPanelButton>
                    {(!loading && isTrume) && <TopPanelButton onClick={share}>Share Results<img src={shareIcon} /></TopPanelButton>}
                </ButtonsWrapper>
            </LeftColumn>
            <AgeInfo predictedAge={predictedAge} chronologicalAge={chronologicalAge}/>
        </TopPanelWrapper>
    </Contents>
}


export const AgePredictResults = ({ chronologicalAge, predictedAge }) => {
    const difference = Math.abs(predictedAge - chronologicalAge).toFixed(1);
    const [years, months] = difference.split('.')
    const lower = predictedAge < chronologicalAge;

    return <Wrapper>
        <TopPanel {...{years, months, lower, chronologicalAge, predictedAge}}/>
    </Wrapper>
}
