import { COUNTRIES } from "../files/countries";
import { getDateFormat, PLACEHOLDER_FORMAT } from "../utils/date"

const DATE_FORMAT = getDateFormat();

const PLACEHOLDER_DATE_FORMAT = getDateFormat(PLACEHOLDER_FORMAT);


export const ONBOARDING_FORM = [
    {
      description: 'Tell us a little bit about yourself',
      question: 'What is your biological sex?',
      type: 'dropdown',
      name: 'biological_sex',
      askOnlyOnce: true,
      options: [
        { label: 'Female', value: 'Female' },
        { label: 'Male', value: 'Male' },
        { label: 'Other', value: 'Other' }
      ]
    },
    {
      description: 'Tell us a little bit about yourself',
      question: 'My birthday is',
      type: 'date',
      dateFormat: DATE_FORMAT,
      placeholder: PLACEHOLDER_DATE_FORMAT,
      name: 'date_of_birth',
      inputMode: 'numeric',
      askOnlyOnce: true,
    },
    {
      description: 'Tell us a little bit about yourself',
      question: 'What gender do you identify with?',
      type: 'dropdown',
      name: 'gender',
      askOnlyOnce: true,
      options: [
        { label: 'Female', value: 'Female' },
        { label: 'Male', value: 'Male' },
        { label: 'Other', value: 'Other' }
      ]
    },
    {
      description: 'Tell us a little bit about yourself',
      question: 'What country are you located in?',
      type: 'dropdown',
      name: 'country',
      askOnlyOnce: true,
      options: COUNTRIES.map(({ label }) => ({ value: label, label })),
      isSearchable: true
    }
]