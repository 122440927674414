import { COUNTRIES } from "../../files/countries";


export const personalDetail = {
    date_of_birth: {
        label: `What's your date of birth?`,
        id: 'date_of_birth',
        type: 'date'
    },
    biological_sex:{
        label: `What's your biological sex?`,
        id: 'biological_sex',
        options: [
            { label: 'Female', value: 'Female' },
            { label: 'Male', value: 'Male' },
            { label: 'Other', value: 'Other' }
        ]
    },
    gender: {
        label: `What gender do you identify with?`,
        id: 'gender',
        options: [
            { label: 'Female', value: 'Female' },
            { label: 'Male', value: 'Male' },
            { label: 'Other', value: 'Other' }
        ]
    },
    country: {
        label: 'What country are you located in?',
        id: 'country',
        options: COUNTRIES.map(({ label }) => ({ value: label, label })),
        isSearchable: true
    },
}