import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { ShareIcon } from '../images/share-icon.js'
import { ArrowLeftIcon } from '../images/arrow-left-icon.js'
import { ShareBanner } from '../components/share-banner.js';
import { PredictImage } from '../components/age-predict-image.js';
import AgePredictNavbar from '../components/predict-bio-age/predict-bio-age-navbar.js';
import { ProgressBar } from '../components/progress-bar.js';
import PredictBioAgeUserForm from '../components/predict-bio-age/predict-bio-age-user-form.js'
import PredictBioAgeFooter from '../components/predict-bio-age/predict-bio-age-footer.js'
import { calculateAge } from "../utils/age.js"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"
import useAuth from '../utils/useAuth.js'
import { fetchUserData } from '../redux/reducer/user.js'
import { useFirebaseWarningRemove } from '../utils/firebase.js';
import { getDateFormat, MOMENT_FORMAT } from '../utils/date.js';
import { convertKilosToPouds, convertToFeetInches, normalizeUnitAnswers } from '../utils/units.js';
import styled from "styled-components"
import moment from 'moment'
import api from "../utils/api.js";


const ProgressBarWrapper = styled.div`
  background: blue;
`

const PredictBioAgeWrapper = styled.div`
  overflow: 'auto';
  position: relative;
  padding: ${props => props.userForm ?  '10px 10px 0px 0px' : '0'};
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: start;

   @media (max-width: 768px) {
      padding: ${props => props.userForm ?  '10px 10px' : '0px 10px 40px 10px'};
      flex: none;
   }
`

const FieldHeaderMobile = ({
  setShareBannerVisible,
  onPreviousQuestion,
}) => {

  return <FieldHeaderMobileWrapper>
      <QuestionButton onClick={onPreviousQuestion}>
        <ArrowLeftIcon fill="#5B637D" />
      </QuestionButton>
      {setShareBannerVisible && <QuestionButton onClick={() => setShareBannerVisible(true)}>
        <ShareIcon fill="#02145E" />
      </QuestionButton>}
  </FieldHeaderMobileWrapper>


}

const QuestionButton = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: 768px) {
    border-radius: 100%;
    border: 1px solid #D9D9D9;
    width: 40px;
    height: 40px;
  }
`

const FieldHeaderMobileWrapper = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    padding: 15px;
    justify-content: space-between;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
  }
`


export const PredictAgeRegistrationForm = () => {
  const [shareBannerVisible, setShareBannerVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth()
  const { currentUser } = useAuth()
  const dispatch = useDispatch()
  useFirebaseWarningRemove();
  
  useEffect(() => {
      if (currentUser) {
          dispatch(fetchUserData())
      };
  }, [currentUser])

  const userData = useSelector((state) => state.user.userData);

  const onSubmit = async (data) => {
    setIsLoading(true)
    try {
      let prevResponses = sessionStorage.getItem('onboardingFormAnswers')
      prevResponses = JSON.parse(prevResponses);
      prevResponses = normalizeUnitAnswers(prevResponses)

      let {
        coffee_consumption,
        tobacco_consumption,
        has_diabetes,
        has_cancer,
        sleep_hours,
        physical_activity_days,
        trouble_sleeping,
        waking_up_condition,
        date_of_birth,
        height_feet,
        height_inches,
        weight
      } = prevResponses;


      date_of_birth = moment(date_of_birth, getDateFormat(MOMENT_FORMAT))
      date_of_birth = date_of_birth.isValid() ?
        date_of_birth :
        moment(prevResponses['date_of_birth'])

      moment().toDate()
      const payload = {
        user_data: {
          'first_name': data.firstName,
          'last_name': data.lastName,
          'email': data.email,
          'password': data.password,
          'terms_accepted': data.termsAccepted,
          date_of_birth: date_of_birth.toDate(),
        },
        survey_data: {
          height_inches: parseInt(height_inches),
          height_feet: parseInt(height_feet),
          weight,
          coffee_consumption: coffee_consumption === 'Yes' ? 1 : 0,
          tobacco_consumption: tobacco_consumption === 'Yes' ? 1 : 0,
          has_diabetes: has_diabetes === 'Yes' ? 1 : 0,
          has_cancer: has_cancer === 'Yes' ? 1 : 0,
          sleep_hours: parseInt(sleep_hours),
          physical_activity_days: parseInt(physical_activity_days),
          trouble_sleeping: trouble_sleeping === 'Yes' ? 1 : 0,
          waking_up_condition: waking_up_condition === 'Yes' ? 1 : 0,
          age: calculateAge(date_of_birth, getDateFormat(MOMENT_FORMAT)),  
        }
      }
      const result = await api.post('/predict-biological-age-form', payload);
      if (result?.data['predicted_bio_age']) {
        if (data.password) {
          await signInWithEmailAndPassword(auth, data.email, data.password);
        }
        navigate('/dashboard/age')
      }
    } catch (error) {
      NotificationManager.error('Error to predict the bio age. Try again later!')
    } finally {
      setIsLoading(false)
    }
  }

  const previousQuestion = () => {
    navigate(-1);
  }

  return (
    <>
      <ShareBanner 
        image={<PredictImage />}
        visible={shareBannerVisible} 
        setVisible={setShareBannerVisible}
      />
      <AgePredictNavbar />
      <ProgressBarWrapper>
        <ProgressBar percent={100} width='100%' background="linear-gradient(271deg, #4F59F0 -3.59%, #B5EDFF 92.5%)" />
      </ProgressBarWrapper>
      <PredictBioAgeWrapper> 
        <FieldHeaderMobile 
        setShareBannerVisible={setShareBannerVisible}
        onPreviousQuestion={previousQuestion} />
        <PredictBioAgeUserForm
          onSubmit={onSubmit}
          isLoading={isLoading}
          userData={userData}
        />
      </PredictBioAgeWrapper>
      <PredictBioAgeFooter openShareModal={() => setShareBannerVisible(true)} />
    </>
  )
}