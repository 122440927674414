import styled from "styled-components"
import { NextStepButton } from "./styles"

const VideoTutorialWrapper = styled.div`
  background: #fff;
  width: 100%;
  height: calc(100dvh - 90px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;
  padding: 64px 0px;

  @media (max-width: 768px) {
    height: 100%;
    width: 100%;
    padding: 64px 0px;
    justify-content: start;
  }
`

const VideoTutorialTitle = styled.h1`
  color: #051F73;
  text-align: center;
  font-family: 'Satoshi-Medium';
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 60%;

  @media (max-width: 768px) {
    width: 90%;
    text-align: center;
  }
`

const VideoIframe = styled.iframe`
  border-radius: 10px;
  width: 560px;
  height: 315px;

  @media (max-width: 768px) {
    width: 95%;
  }
`

export const VideoTutorial = ({ nextStep, link }) => {
  return (
    <VideoTutorialWrapper>
      <VideoTutorialTitle>You can watch our step by step tutorial or keep on reading our detailed instructions</VideoTutorialTitle>
      <VideoIframe
        src={link}
        title="TruMe test instructions"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      />
      <NextStepButton onClick={nextStep}>
       Proceed to instructions
      </NextStepButton>
    </VideoTutorialWrapper>
  )
}