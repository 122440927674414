import styled from 'styled-components';

const SecondaryButtonWrapper = styled.button`
    padding: 16px 20px;
    border-radius: 100px;
    background: ${props => props.active ? '#F5F6FA' : 'none'};
    color: ${props => props.active ? '#4F59F0' : '#051F73'};
    font-family: 'Satoshi-Bold';
    align-items: center;
    justify-content: center;
    display: flex;
    text-wrap: nowrap;
    cursor: pointer;
    border: none;
    font-size: 16px;

    img {
        height: 15px;
        width: 15px;
        margin-left: 15px;
    }
`;

export const SecondaryButton = ({ children, active = false, ...props}) => {

    return <SecondaryButtonWrapper {...props} active={active}>
        {children}
    </SecondaryButtonWrapper>

}