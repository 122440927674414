import { useState } from 'react';
import { OnboardingForm } from '../components/onboarding-form.js';
import PredictBioAgeFooter from '../components/predict-bio-age/predict-bio-age-footer.js';
import { PREDICT_AGE_FORM } from '../constants/predict-age-form.js';
import { PredictImage } from '../components/age-predict-image.js';
import { ShareBanner } from '../components/share-banner.js';
import { useNavigate } from 'react-router-dom';
import { useIsTruMe } from '../utils/white-label.js';

export const PredictBioAge = () => {

  const [shareBannerVisible, setShareBannerVisible] = useState(false);
  const navigate = useNavigate();

  const onEndForm = (answers) => {
    sessionStorage.setItem('onboardingFormAnswers', JSON.stringify(answers))
    navigate('/predict-bio-age/form/2');
  }

  return (<div>
      <ShareBanner 
        image={<PredictImage />}
        visible={shareBannerVisible} 
        setVisible={setShareBannerVisible}
      />
      <OnboardingForm 
      setShareBannerVisible={setShareBannerVisible}
      footer={<PredictBioAgeFooter openShareModal={() => setShareBannerVisible(true)} />}
      fields={PREDICT_AGE_FORM}
      onEndForm={onEndForm} />
    </div>
  )
}