import styled from 'styled-components'
import AutosizeInput from 'react-textarea-autosize'

const FieldQuestion = styled.span`
  color: #051F73;
  font-family: 'Satoshi-Medium';
  font-size: 32px;
  font-style: normal;
  font-weight: normal;
  line-height: 36px;
  letter-spacing: 0;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 22px;
  }
`

const Input = styled(AutosizeInput)`
  font-size: 18px;
  outline: none !important;
  font-family: 'Satoshi-Medium';
  padding: 10px 15px;
  padding-right: 15px;
  resize: none;

  width: 300px;
  box-sizing: border-box;
  border: 1px solid #DBDBDB;
  border-radius: 8px;
  
  @media (max-width: 768px) {
    width: initial;
  }
`;

const FieldTextArea = ({
  field,
  fieldAnswers,
  onUpdate,
  className,
  submitAction,
}) => {

  const value = fieldAnswers[field.name];

  const onKeyDown = (e) => {
    if (e.key === 'Enter' && value) submitAction();
  }

  return <FieldQuestion className={`ml-3 ${className}`}>
      <Input
        style={{ width: 300 }}
        placeholder='Your answer goes here'
        value={value}
        maxRows={2}
        onChange={(e) => onUpdate(!!e.target.value?.length, { [field.name]: e.target.value })}
        onKeyDown={onKeyDown} />
    </FieldQuestion>
}

export default FieldTextArea