/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './tailwind.css';
import App from './App';
import { store } from './redux/store'
import { Provider } from 'react-redux'
import initialize from './utils/firebase';
import { GraphTooltip } from './components/graph-tooltip';
import * as Sentry from "@sentry/react";


if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    environment: process.env.REACT_APP_ENVIRONMENT,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1, 
    profilesSampleRate: 1.0,
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}


initialize();


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <GraphTooltip />
      <App />
    </Provider>
  </React.StrictMode>
);
