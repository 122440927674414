import React from 'react';
import styled from 'styled-components';
import { Row } from './defaults';
import { House } from '../images/house.js';
import { UserRibbon } from './user-ribbon.js';
import { Body } from '../images/body-icon.js';
import { DnaIcon } from '../images/dna-icon.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import backIcon from '../images/back-arrow.svg';
import { MultibrandLogo } from './multibrand-logo.js';

const GoBackWrapper = styled(Row)`
    height: 56px;
    align-items: center;   
    margin: 12px 24px;

    @media (max-width: 768px) {
        height: 43px;
        margin: 14px 0px;
    }

`;

const BackWrapper = styled.img`
    cursor: pointer;
    height: 12px;
    margin-right: 24px;


    @media (max-width: 768px) {
        margin-right: 15px;
    }
`;

const Separator = styled.div`
    width: 1px;
    height: 100%;
    background: rgba(0,0,0,.1);
`;

const RouteTitle = styled.div`
    margin-left: 24px;
    font-size: 18px;
    color: #051F73;


    @media (max-width: 768px) {
        margin-left: 15px;
    }

    @media (max-width: 800px) {
        font-size: 14px
    }
`;

const Logo = styled(MultibrandLogo)`
    height: 56px;
    width: 120px;
    margin: 12px 24px;

    @media (max-width: 768px) {
        height: 43px;
        width: 90px;
        margin: 14px 0px;
    }

    margin-left: 0px;
    

`;  

const Wrapper = styled(Row)`
    border-bottom: ${props => props.dark ? '1px solid #5963FD' : '1px solid #E0E0E0'};
    align-items: center;
    background: ${props => props.dark ? '#4F59F0' : 'white'};
    padding-right: 24px;
    padding-left: 24px;
    z-index: 1;

    @media (max-width: 768px) {
        padding-right: 20px;
        padding-left: 20px;
    }
`;

const Menu = styled(Row)`
    gap: 32px;
    align-self: stretch;
    align-items: center;
`;

const LinkBox = styled.a`
    text-decoration: none;
    display: flex;
    flex-direction: Column;
    height: 100%; 
    border-bottom: ${props => props.selected ? '2px solid #4F59F0' : '2px solid transparent'};   
`;

const LinkWrapper = styled(Row)`
    background: ${props => props.selected ? '#F5F6FA' : 'white'};
    color: ${props => props.selected ? '#4F59F0' : '#5B637D'};
    font-size: 18px;
    padding: 13px 16px;
    align-items: center;
    cursor: pointer;
    border-radius: 8px;
    position: relative;
`;

const Text = styled.div`
    margin-left: 6px;
    color: ${props => props.selected ? '#4F59F0' : '#5B637D'};

    @media (max-width: 762px) {
        display: none;
        margin-left: 0px;
    }
`;

const NavbarButtons = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    @media (max-width: 762px) {
        display: none;
    }
`



const Link = ({Icon = House, children, href, ...props}) => {

    const location = useLocation();
    const selected = location.pathname === href;

    return <LinkBox href={href} selected={selected}>
        <div style={{ flex: 1 }} />
        <LinkWrapper {...props} selected={selected}>
            <Icon fill={selected ? '#4F59F0' : '#5B637D'} />
            <Text selected={selected}>{children}</Text>
        </LinkWrapper>
        <div style={{ flex: 1 }} />
    </LinkBox>
}

const goBackRoutes = {
    '/dashboard/profile': 'Your Profile'
}

export const DashboardHeader = ({ dark = false }) => {
    const geneticData = useSelector((s) => s.user.userData.genetic_kit);
    const navigate = useNavigate()

    const path = useLocation().pathname;
    const shouldShowBack = Object.keys(goBackRoutes).includes(path);

    const goBack = () => {
        navigate(-1)
    }

    const menuItems = [
        {
            name: 'Home',
            iconComponent: <House fill='#5B637D' />,
            icon: House,
            url: '/dashboard',
            showItem: true,
            selected: true
        },
        {
            name: 'Biological Age',
            iconComponent: <Body fill='#5B637D' />,
            icon: Body,
            url: '/dashboard/age',
            showItem: true,
            selected: false
        },
        {
            name: 'DNA Analysis',
            iconComponent: <DnaIcon fill='#5B637D' />,
            icon: DnaIcon,
            url: '/dashboard/genetic',
            showItem: !!geneticData?.data,
            selected: false
        }
    ]

    return <Wrapper dark={dark}>
        <div style={{ flex: 1 }}>
        {
            shouldShowBack ? <GoBackWrapper>
                <BackWrapper src={backIcon}  onClick={goBack}/>
                <Separator />
                <RouteTitle>{goBackRoutes[path]}</RouteTitle>
            </GoBackWrapper> :
            <Logo />
        }
        </div>
        
        <NavbarButtons>
            <Menu>
                {menuItems.map(item => item.showItem && <Link href={item.url} Icon={item.icon} selected={item.selected}>{item.name}</Link>)}
            </Menu>
        </NavbarButtons>
        <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <UserRibbon menuItems={menuItems} />
        </div>
    </Wrapper>

}