import { useState, useEffect } from "react";

const useKeyboardOpen = () => {

    const [keyboardOpen, setIsKeyboardOpen] = useState(false);
    const [keyboardHeight, setKeyboardHeight] = useState(0);

    
    useEffect(() => {
        const handleViewportChange = () => {
            if (window.visualViewport) {
                const viewportHeight = window.visualViewport.height;
                const windowHeight = window.innerHeight;

                if (viewportHeight < windowHeight) {
                    setIsKeyboardOpen(true);
                    setKeyboardHeight(windowHeight - viewportHeight);
                } else {
                    setIsKeyboardOpen(false);
                    setKeyboardHeight(0);
                }
            }
        };

        if (window.visualViewport) {
            window.visualViewport.addEventListener("resize", handleViewportChange);
            handleViewportChange(); // Run initially
        }

        return () => {
            if (window.visualViewport) {
                window.visualViewport.removeEventListener("resize", handleViewportChange);
            }
        };
    }, []);

    

    return {
        keyboardOpen,
        keyboardHeight
    }

};

export default useKeyboardOpen;