import { useEffect, useState } from "react";
import api from "./api"

export const getWhiteLabelData = async () => {
    let brandData = sessionStorage.getItem('BRAND_DATA');
    if (brandData) {
        document.title = JSON.parse(brandData).name || 'TruMe Labs';
        return JSON.parse(brandData);
    }
    const host = window.location.hostname;
    const response = await api.get('/brand/open?subdomain=' + host);
    if (!response.data.valid_domain) window.location.href = '/404';
    document.title = response.data.name || 'TruMe Labs';
    sessionStorage.setItem('BRAND_DATA', JSON.stringify(response.data));
    return response.data;
}

export const logo = async () => {
    const whiteLabelData = await getWhiteLabelData();
    return whiteLabelData.logo_url;
}

export const supportEmail = async () => {
    const whiteLabelData = await getWhiteLabelData();
    return whiteLabelData.support_email || 'support@trumelabs.com';
}

export const website = async () => {
    const whiteLabelData = await getWhiteLabelData();
    return whiteLabelData.website || window.location.host;
}

export const subdomain = async () => {
    const whiteLabelData = await getWhiteLabelData();
    return `https://${whiteLabelData.subdomain}`;
}

export const brandName = async () => {
    const whiteLabelData = await getWhiteLabelData();
    return whiteLabelData.name || 'TrumeLabs';
}

export const brandId = async () => {
    const whiteLabelData = await getWhiteLabelData();
    return whiteLabelData.id || 1;
}

export const useWhiteLabelData = () => {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});

    const fetch = async () => {
        const whiteLabelData = await getWhiteLabelData();
        setData(whiteLabelData);
        setLoading(false)
    }

    useEffect(() => { fetch() }, []);

    return {
        loading, data
    }

}


export const useIsTruMe = () => {

    const [loading, setLoading] = useState(true);
    const [isTrume, setIsTrume] = useState({});

    const fetch = async () => {
        const whiteLabelData = await getWhiteLabelData();
        setIsTrume(whiteLabelData.id === 1);
        setLoading(false)
    }

    useEffect(() => { fetch() }, []);

    return {
        loading, isTrume
    }

}


export const useBiologicalAgeNaming = () => {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});

    const fetch = async () => {
        const whiteLabelData = await getWhiteLabelData();
        setData(whiteLabelData);
        setLoading(false)
    }

    useEffect(() => {
        fetch()
    }, []);

    return {
        loading, copy: data?.id === 1 ? 'TruMe Age' : 'Biological Age', data
    }

}