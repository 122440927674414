import React, { useState } from 'react';
import { LinkTertiary, Container, Input, Row } from '../components/defaults';
import styled from 'styled-components';
import mainbg from '../images/main-bg.svg';
import sphere from '../images/sphere.svg';
import topCurve from '../images/top-curve.svg';
import backlinkArrow from '../images/arrow-left.svg';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { AuthHeader } from '../components/auth-header';
import { 
    Contents, 
    FormBox, 
    FormContents, 
    MainBg, 
    Sphere, 
    SubmitButton, 
    ThisTitle, 
    TopCurve 
} from '../components/auth-defaults';
import api from '../utils/api';

const BacklinkArrow = styled.img`
    height: 15px;
    margin-right: 10px;
`;

const BackLink = styled(LinkTertiary)`
    margin-bottom: 40px;
    align-self: flex-start;
    margin-left: 0px;

    @media (max-width: 762px) {
        margin-bottom: 20px;
    }
`;

const Message = styled.div`
    background: #F4F3FE;
    color: #051F73;
    font-family: 'Satoshi-Medium';
    font-size: 18px;
    padding: 16px 24px;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: -10px;
`;

export const RecoverPassword = () => {

    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [sent, setSent] = useState(false)

    const submit = async () => {
        setLoading(true);
        const finalEmail = email
        api.post('/request-password', {email});
        setMessage(`We sent a message to ${finalEmail} with instructions to recover your password.`)
        setLoading(false);
        setSent(true);
    }

    return <Container style={{ background: '#4F59F0'}}>
        <AuthHeader />
        <Contents> 
                <FormBox>
                    <FormContents>
                        <BackLink to={'/sign-in'}>
                            <BacklinkArrow src={backlinkArrow} />
                            Login
                        </BackLink>
                        <ThisTitle>Request a new password.</ThisTitle>
                        {message && <Message>{message}</Message>}
                        <Input 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            label='Email' 
                            type='email'
                            style={{ marginTop: 32 }} />
                        <div style={{ flex: 1 }} />
                        <Row style={{ alignItems: 'center', marginTop: 50 }}>
                            <SubmitButton loading={loading} disabled={loading || !email || sent} onClick={submit}>Get New Password</SubmitButton>
                        </Row>
                    </FormContents>
                    <TopCurve src={topCurve}/>
                </FormBox>
            <MainBg src={mainbg}/>
            <Sphere src={sphere}/> 
        </Contents>
        
    </Container>    
}