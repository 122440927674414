/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import useAuth from './utils/useAuth';
import styled from 'styled-components';
import { Outlet, Route, BrowserRouter as Router, Routes, useLocation, useNavigate, Navigate } from 'react-router-dom';
import { Column } from "./components/defaults";
import GlobalStyle from "./components/globals";
import { BarcodeInput } from "./pages/kit-registration/number-input";
import { KitSuccess } from "./pages/kit-registration/success";
import { Dashboard } from "./pages/dashboard";
import { SignIn } from "./pages/sign-in";
import { Footer } from "./components/footer";
import { RecoverPassword } from "./pages/recover-pw";
import { DashboardHeader } from "./components/dashboard-header";
import { AgeDashboardRouter } from "./pages/age-dashboard";
import { GeneticDashboard } from "./pages/genetic-dashboard";
import { PredictBioAge } from "./pages/predict-bio-age";
import { ResetPassword } from "./pages/reset-pw";
import { ChangePassword } from "./pages/change-pw";
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import Share from "./pages/share";
import Supplements from "./pages/supplements";
import { CreatePassword } from "./pages/create-pw";
import { Viewer } from "./viewer";
import { initializeHeap } from "./utils/heap";
import { Impersonate } from "./pages/impersonate";
import { Profile } from './pages/profile'
import { UnsubscribeEmail } from "./pages/unsubscribe";
import { AgePredict } from "./pages/age-predict";
import { SignUp } from "./pages/user-registration-3";
import { PredictAgeRegistrationForm } from './pages/predict-bio-age-registration-form';
import { SignUpOnboardingDetails } from './pages/user-registration-3/onboarding-form';
import { SignedInRegisterKit } from './pages/signed-in-register-kit';
import { KitSubmissionTutorial } from './pages/kit-submission-tutorial';
import { NotFound } from './pages/not-found';


const DashboardContainer = styled(Column)`
  position: relative;
  min-height: 100vh;
`;

initializeHeap();

const SignedInOnly = ({ header, children, skipOutlet, redirectToSelf }) => {
  const pathname = useLocation().pathname;
  const { currentUser, isLoading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const redirectUrl = redirectToSelf ? `?redirect=${pathname}` : '';
    if (!isLoading && !currentUser) navigate(`/sign-in${redirectUrl}`, { replace: true });
  }, [isLoading, currentUser])

  if (isLoading) return <div style={{ minHeight: '100vh' }}/>

  return <>
    {header}
    {skipOutlet ? children : <Outlet />}
  </>
};

const signedOutExclusions = [
  'sign-in/reset-pw',
  'sign-in/create-pw'
];

const SignedOutOnly = () => {
  const { currentUser, isLoading } = useAuth();
  const navigate = useNavigate();
  const search = useLocation().search;
  const redirectUrl = new URLSearchParams(search).get('redirect');

  const shouldSkipRedirection = () => {
    for (let page of signedOutExclusions) {
      if (window.location.href.includes(page)) return true;
    };
  }

  useEffect(() => {
    if (shouldSkipRedirection()) return;
    if (!isLoading && currentUser) navigate(redirectUrl || '/dashboard', { replace: true });
  }, [currentUser, isLoading]);

  if (isLoading) return <div style={{ minHeight: '100vh' }}/>

  return <Outlet />;
}

const UseScroll = () => {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname])

  return <div />

}

function App() {

  return <DashboardContainer style={{ zIndex: 1, position: 'relative'}}>
    <GlobalStyle />
    <NotificationContainer />
    <Router>
      <UseScroll />
      <Routes>
        <Route path="/sign-in" element={<SignedOutOnly />}>
          <Route path='/sign-in' element={<SignIn />} />
          <Route path='/sign-in/kit/:barcode' element={<SignIn />} />
          <Route path='/sign-in/recover-pw' element={<RecoverPassword />} />
          <Route path='/sign-in/reset-pw' element={<ChangePassword />} />
          <Route path='/sign-in/reset-pw-instructions' element={<ResetPassword />} />
          <Route path='/sign-in/create-pw' element={<CreatePassword />} />
        </Route>
        <Route path="/sign-up/kit/:barcode" element={<SignedOutOnly />}>
          <Route path='' element={<SignUp />} />
        </Route>
        <Route path="/sign-up/1" element={<SignedOutOnly />}>
          <Route path='' element={<SignUp />} />
        </Route>
        <Route path="/kit/number" element={<BarcodeInput showSignIn/>} />

        <Route path="/kit-instructions/biological-age-test" element={<KitSubmissionTutorial />} />
        <Route path="/kit-instructions/dna-methylation" element={<KitSubmissionTutorial />} />
        <Route path="/registration/success" element={<KitSuccess />} />
        <Route path="/dashboard" element={<SignedInOnly header={<DashboardHeader />} />}>
          <Route path="/dashboard/" element={<Dashboard />} />
          <Route path="/dashboard/age" element={<AgeDashboardRouter />} />
          <Route path="/dashboard/genetic" element={<GeneticDashboard />} />
          <Route path="/dashboard/profile" element={<Profile />} />
          <Route path='/dashboard/register-kit/:barcode' element={<SignedInRegisterKit />} />
        </Route>
        <Route path='/sign-up/2' element={<SignedInOnly />} >
          <Route path='' element={<SignUpOnboardingDetails />} />
        </Route>
        <Route path='/unsubscribe' element={<SignedInOnly redirectToSelf header={<DashboardHeader />} />} >
          <Route path='/unsubscribe' element={<UnsubscribeEmail />} />
        </Route>
        <Route path="/" element={<Navigate to="/sign-in" replace />} /> {/* Redirect root to /sign-in */}
        <Route path='/sign-in-token' element={<Impersonate />} />
        <Route path='/share/:token' element={<Share />} />
        <Route path='/predict-bio-age' element={<AgePredict />} />
        <Route path='/predict-bio-age/form/1' element={<PredictBioAge />} />
        <Route path='/predict-bio-age/form/2' element={<PredictAgeRegistrationForm />} />

        {process.env.REACT_APP_ENVIRONMENT === 'development' && <Route path="/viewer" element={<Viewer />} />}
        <Route path="/supplements" element={<Supplements />} />
        <Route path='/404' element={<NotFound />} />
      </Routes>
    </Router>
    <Footer />
  </DashboardContainer>
  
}

export default App;
