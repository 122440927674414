/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import styled from 'styled-components'

const FieldQuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  justify-content: space-between;
`

const FieldSelectorWrapper = styled.button`
  display: flex;
  min-width: 150px;
  min-height: 48px;
  padding: 13px 24px 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #E2E2FF;
  background: #FFF;
  color: #4D56F2;
  cursor: pointer;

  border-color: ${props => props.isSelected ? '#4D56F2' : '#E2E2FF'};
  background: ${props => props.isSelected ? '#4D56F2' : '#FFF'};
  color: ${props => props.isSelected ? '#FFF' : '#4D56F2'};

  text-align: center;
  leading-trim: both;

  text-edge: cap;
  font-family: 'Satoshi-Medium';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.26px;

  pointer-events: ${props => (props.disabled && !props.isSelected) ? 'none' : 'auto'};
  opacity: ${props => (props.disabled && !props.isSelected) ? 0.5 : 1};

  @media (max-width: 768px) {
    width: ${props => props.type === 'yes_no' ? '85vw' : 'initial'};
    min-width: 103px;
    font-size: 16px;
    gap: 0px;
    padding: 5px;
  }
`


const FieldSelectors = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
  width: ${props => props.type == 'yes_no' ? '100%' : '50%'};

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-height: 700px) {
    width: 100%;
  }
    
`

const FieldSelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
`


const FieldSelector = ({ field, onUpdate }) => {
  const [answer, setAnswer] = useState(null)
  
  useEffect(() => {
    let timeout;
    if (answer) timeout = setTimeout(() => {
      onUpdate(true, answer);
    }, 600)

    return () => clearTimeout(timeout)
  }, [answer])

  const onSelectOption = (e) => {
    setAnswer({ [field.name]: e.target.value })
  }

  return <FieldSelectWrapper>
      <FieldQuestionWrapper type={field.optionsType}>
        <FieldSelectors type={field.optionsType}>
          {field.options.map(option => {
            return (
              <FieldSelectorWrapper
                value={option.label}
                onClick={onSelectOption}
                disabled={answer !== null}
                type={field.optionsType}
                isSelected={answer && answer[field.name] === option.label}
              >
                {option.label}
              </FieldSelectorWrapper>
            )
          })}
        </FieldSelectors>
      </FieldQuestionWrapper>
    </FieldSelectWrapper>
}

export default FieldSelector