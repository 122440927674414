import { getDateFormat, PLACEHOLDER_FORMAT } from "../utils/date"
import { COUNTRIES } from "../files/countries";
const DATE_FORMAT = getDateFormat();
const PLACEHOLDER_DATE_FORMAT = getDateFormat(PLACEHOLDER_FORMAT);


export const KIT_REGISTRATION_FORM = [
    {
      description: 'Please answer the questions below to register your kit',
      question: 'Did you take your sample today?',
      type: 'selector',
      name: 'sample_today',
      options: [
        {
          label: 'Yes'
        },
        {
          label: 'No'
        }
      ]
    },
    {
      description: 'Please answer the questions below to register your kit',
      question: 'I took my sample on',
      type: 'date',
      dateFormat: DATE_FORMAT,
      placeholder: PLACEHOLDER_DATE_FORMAT,
      name: 'original_registered_at',
      inputMode: 'numeric',
      condition: (values) => values?.sample_today === 'No',
      defaultAnswer: (values) => null
    },
    {
      description: 'Tell us a little bit about yourself',
      question: 'My birthday is',
      demographicData: true,
      type: 'date',
      dateFormat: DATE_FORMAT,
      placeholder: PLACEHOLDER_DATE_FORMAT,
      name: 'date_of_birth',
      inputMode: 'numeric',
      askOnlyOnce: true,
    },
    {
        description: 'Tell us a little bit about yourself',
        question: 'What is your biological sex?',
        demographicData: true,
        type: 'dropdown',
        name: 'biological_sex',
        askOnlyOnce: true,
        options: [
          { label: 'Female', value: 'Female' },
          { label: 'Male', value: 'Male' },
          { label: 'Other', value: 'Other' }
        ]
      },
      {
        description: 'Tell us a little bit about yourself',
        question: 'What gender do you identify with?',
        type: 'dropdown',
        demographicData: true,
        name: 'gender',
        askOnlyOnce: true,
        options: [
          { label: 'Female', value: 'Female' },
          { label: 'Male', value: 'Male' },
          { label: 'Other', value: 'Other' }
        ]
      },
      {
        description: 'Tell us a little bit about yourself',
        question: 'What country are you located in?',
        demographicData: true,
        type: 'dropdown',
        name: 'country',
        askOnlyOnce: true,
        options: COUNTRIES.map(({ label }) => ({ value: label, label })),
        isSearchable: true
      },
    {
      description: 'Tell us a little bit about yourself',
      question: 'My height is',
      type: 'dropbox',
      name: 'height',
      renderInternalQuestion: true,
      inputMode: 'numeric',
      options: [
        {
          label: 'ft.',
          inputs: [
            {
              placeholder: '00',
              legend: 'ft',
              name: 'height_feet',
              inputMode: 'numeric',
              type: 'number',
              minValue: 0
            },
            {
              placeholder: '00',
              legend: 'in',
              name: 'height_inches',
              inputMode: 'numeric',
              type: 'number',
              minValue: 0
            },
          ]
        },
        {
          label: 'cm',
          inputs: [
            {
              placeholder: '00',
              legend: 'cm',
              name: 'height_centimeter',
              inputMode: 'numeric',
            }
          ]
        }
      ]
    },
    {
      description: 'Tell us a little bit about yourself',
      question: 'I weigh',
      type: 'dropbox',
      name: 'weight',
      renderInternalQuestion: true,
      inputMode: 'numeric',
      options: [
        {
          label: 'lbs.',
          inputs: [
            {
              placeholder: '0',
              legend: 'lbs',
              name: 'weight_pounds',
              inputMode: 'numeric',
            },
          ]
        },
        {
          label: 'kg',
          inputs: [
            {
              placeholder: '0',
              legend: 'kg',
              name: 'weight_kilos',
              inputMode: 'numeric',
            }
          ]
        }
      ]
    },
    {
      description: 'Let’s dive into some of your habits.',
      question: 'Do you drink coffee?  ☕️',
      type: 'selector',
      name: 'coffee_consumption',
      optionsType: 'yes_no',
      options: [
        {
          label: 'Yes'
        },
        {
          label: 'No'
        }
      ]
    },
    {
      description: 'Let’s dive into some of your habits.',
      question: 'Do you Smoke?  🚬',
      type: 'selector',
      name: 'tobacco_consumption',
      optionsType: 'yes_no',
      options: [
        {
          label: 'Yes'
        },
        {
          label: 'No'
        }
      ]
    },
    {
      description: 'Let’s dive into some of your habits.',
      question: 'How many alcoholic drinks do you have per week?',
      type: 'selector',
      name: 'alcohol_consumption',
      options: [  
        { label: '0', value: '0' },
        { label: '1-2', value: '1-2' },
        { label: '3-5', value: '3-5'},
        { label: '6-9', value: '6-9'},
        { label: '10+', value: '10+'}
      ]
    },
    
    {
      description: 'Let’s dive into some of your habits.',
      question: 'Do you wake up rested?  🥱',
      type: 'selector',
      name: 'waking_up_condition',
      optionsType: 'yes_no',
      options: [
        {
          label: 'Yes'
        },
        {
          label: 'No'
        }
      ]
    },
    {
      description: 'Let’s dive into some of your habits.',
      question: 'Do you have trouble sleeping  💤',
      type: 'selector',
      name: 'trouble_sleeping',
      optionsType: 'yes_no',
      options: [
        {
          label: 'Yes'
        },
        {
          label: 'No'
        }
      ]
    },
    {
      description: 'Let’s dive into some of your habits.',
      question: 'On average, how many hours do you sleep per night?  🛌',
      type: 'selector',
      name: 'sleep_hours',
      options: [
        {
          label: '5 or less'
        },
        {
          label: '6'
        },
        {
          label: '7'
        },
        {
          label: '8'
        },
        {
          label: '9'
        },
        {
          label: '10 or more'
        },
      ]
    },
    {
      description: 'Let’s dive into some of your habits.',
      question: 'On average, how many days per week do you exercise?  🏋',
      type: 'selector',
      name: 'physical_activity_days',
      options: [
        {
          label: '1'
        },
        {
          label: '2'
        },
        {
          label: '3'
        },
        {
          label: '4'
        },
        {
          label: '5'
        },
        {
          label: '6'
        },
        {
          label: '7'
        },
      ]
    },
    {
      description: 'Let’s dive into some of your habits.',
      question: 'Do you take any supplements?',
      type: 'selector',
      name: 'supplements_yes_no',
      optionsType: 'yes_no',
      options: [
        {
          label: 'Yes'
        },
        {
          label: 'No'
        }
      ]
    },
    {
      description: 'Let’s dive into some of your habits.',
      question: 'What supplements do you take?',
      type: 'textarea',
      name: 'supplement_consumption',
      condition: (values) => values?.supplements_yes_no === 'Yes',
      defaultAnswer: () => 'No',
    },
    {
      description: 'Let’s dive into some of your habits.',
      question: 'Are you currently taking prescription medications?',
      type: 'selector',
      name: 'medicine_yes_no',
      optionsType: 'yes_no',
      options: [
        {
          label: 'Yes'
        },
        {
          label: 'No'
        }
      ]
    },
    {
      description: 'Let’s dive into some of your habits.',
      question: 'What medications do you take?',
      type: 'textarea',
      name: 'medicine_consumption',
      condition: (values) => values?.medicine_yes_no === 'Yes',
      defaultAnswer: () => 'No',
    },
    {
      description: 'We’re almost there! just a couple more questions.',
      question: 'Have you been diagnosed with cancer?  🩺',
      type: 'selector',
      name: 'has_cancer',
      optionsType: 'yes_no',
      options: [
        {
          label: 'Yes'
        },
        {
          label: 'No'
        }
      ]
    },
    {
      description: 'We’re almost there! just a couple more questions.',
      question: 'Have you been diagnosed with diabetes?  🩺',
      type: 'selector',
      name: 'has_diabetes',
      optionsType: 'yes_no',
      options: [
        {
          label: 'Yes'
        },
        {
          label: 'No'
        }
      ]
    },
    {
      description: 'We’re almost there! just a couple more questions.',
      question: 'How would you rate your overall health?',
      type: 'selector',
      name: 'overall_health',
      options: [
        { label: 'Excellent', value: 'Excellent' },
        { label: 'Good', value: 'Good' },
        { label: 'Fair', value: 'Fair'},
        { label: 'Poor', value: 'Poor'}
      ]
    },
    {
      description: 'We’re almost there! just a couple more questions.',
      question: 'How would you rate your mental health?',
      type: 'selector',
      name: 'mental_health',
      options: [
        { label: 'Excellent', value: 'Excellent' },
        { label: 'Good', value: 'Good' },
        { label: 'Fair', value: 'Fair'},
        { label: 'Poor', value: 'Poor'}
      ]
    },
    {
      description: 'We’re almost there! just a couple more questions.',
      question: 'Are you satisfied with your social life?',
      type: 'selector',
      name: 'social_life',
      options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
        { label: 'Rather Not Say', value: 'Rather Not Say'}
      ]
    },
    {
      description: 'We’re almost there! just a couple more questions.',
      question: 'How stressed are you?',
      type: 'selector',
      name: 'stress',
      options: [
        { label: 'Not at all', value: 'Not at all'},
        { label: 'Somewhat stressed', value: 'Somewhat stressed' },
        { label: 'Really stressed', value: 'Really stressed' },
        { label: 'Extremely stressed', value: 'Extremely stressed' },
      ]
    },
]