/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'
import styled from "styled-components"
import loadingIcon from '../images/3-dots-move.svg';
import arrowRight from '../images/arrow-right.svg'
import { ArrowLeftIcon } from '../images/arrow-left-icon.js'
import FieldDate from './onboarding-form/field-date.js'
import FieldInputsWithDropbox from './onboarding-form/field-input-with-dropbox.js'
import FieldSelector from './onboarding-form/field-selector.js'
import { ProgressBar } from './progress-bar';
import AgePredictNavbar from "./predict-bio-age/predict-bio-age-navbar.js"
import useAuth from '../utils/useAuth'
import { fetchUserData } from '../redux/reducer/user'
import { useFirebaseWarningRemove } from '../utils/firebase.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { ShareIcon } from '../images/share-icon.js';
import FieldDropdown from './onboarding-form/field-dropdown.js';
import { Column } from './defaults.js';
import FieldTextArea from './onboarding-form/field-textarea.js';
import { useIsTruMe } from '../utils/white-label.js';
import useKeyboardOpen from '../utils/use-keyboard-open.js';
import { getDateFormat, MOMENT_FORMAT } from "../utils/date.js";


const OnboardingFormWrapper = styled.div`
  height: ${ props => !props.keyboardOpen ? `calc(100dvh - 90px)` : `calc(100dvh - ${props.keyboardHeight}px)`};
  padding: 0px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;

   @media (max-width: 768px) {
      padding: 0px 10px 40px 10px;
      flex: none;
   }
`

const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  gap: 40px;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    justify-content: space-between;
    gap: 0px;
    flex: 1;
  }
`

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    margin: auto 0;
  }
`

const FieldDescription = styled.span`
  color: #5B637D;
  font-family: 'Satoshi-Medium';
  font-size: 19px;
  font-style: normal;
  font-weight: normal;
  line-height: 36px;
  letter-spacing: 0.306px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 22px;
  }
`

const SubmitButton = styled.button`
  width: fit-content;
  background-color: #4F59F0;
  border-radius: 48px;
  padding: 13px 16px;
  border: none;
  color: #fff;
  display: flex;
  gap: 6px;
  align-items: center;
  font-family: 'Satoshi-Bold';
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0;
  opacity: ${props => props.disabled ? '0.8' : '1'};
  cursor: ${props => props.disabled ? 'initial' : 'pointer'};
  justify-self: center;

  @media (max-width: 768px) {
    align-self: center;
    display: flex;
    width: 95%;
    justify-content: center;
    transition: 'transform 0.3s ease, top 0.3s ease';
  }
`

const Icon = styled.img``

const FieldDescriptionWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`

const QuestionButton = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: 768px) {
    border-radius: 100%;
    border: 1px solid #D9D9D9;
    width: 40px;
    height: 40px;
  }
`

const FieldHeaderMobileWrapper = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    padding: 15px;
    justify-content: space-between;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
  }
`

const FieldDescriptionLeft = styled.div`
  display: flex;

  @media (max-width: 768px) {
    display: none;
  }
`

const FieldQuestion = styled.span`
  color: #051F73;
  font-family: 'Satoshi-Medium';
  font-size: 32px;
  font-style: normal;
  font-weight: normal;
  line-height: 36px;
  letter-spacing: 0;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`

const FieldQuestionWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.column ? 'column' : 'row'};
  gap: ${props => props.column ? '30px' : '3px'};
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
`;

const ProgressBarWrapper = styled.div`
  background: blue;
`

const fieldTypes = {
  date: FieldDate,
  dropbox: FieldInputsWithDropbox,
  selector: FieldSelector,
  dropdown: FieldDropdown,
  textarea: FieldTextArea,
}

const Field = ({
  field,
  fieldAnswers,
  onUpdate,
  onPreviousQuestion,
  hasPreviousQuestion,
  submitAction,
  myDivRef
}) => {
  const FieldType = fieldTypes[field.type]
  return <FieldWrapper ref={myDivRef}>
    <FieldDescriptionWrapper>
      <FieldDescriptionLeft>
        {hasPreviousQuestion() && (
          <QuestionButton onClick={onPreviousQuestion}>
            <ArrowLeftIcon fill="#5B637D" />
          </QuestionButton>
        )}
      </FieldDescriptionLeft>
      <FieldDescription>{field.description}</FieldDescription>
    </FieldDescriptionWrapper>
    <FieldQuestionWrapper column={['dropdown', 'selector', 'textarea'].includes(field.type) } >
      {
        !field.renderInternalQuestion && <FieldQuestion>
          {field.question}
        </FieldQuestion>
      }
      <FieldType
        field={field}
        fieldAnswers={fieldAnswers}
        onUpdate={onUpdate}
        submitAction={submitAction}
      />
    </FieldQuestionWrapper>
  </FieldWrapper>
}


const FieldHeaderMobile = ({
  setShareBannerVisible,
  hasPreviousQuestion,
  onPreviousQuestion
}) => {

  const { loading, isTrume } = useIsTruMe();


  return <FieldHeaderMobileWrapper>
      {hasPreviousQuestion() && (
        <QuestionButton onClick={onPreviousQuestion}>
          <ArrowLeftIcon fill="#5B637D" />
        </QuestionButton>
      )}
      {(setShareBannerVisible && !loading && isTrume) && <QuestionButton onClick={() => setShareBannerVisible(true)}>
        <ShareIcon fill="#02145E" />
      </QuestionButton>}
  </FieldHeaderMobileWrapper>


}

const LoadingScreenWrapper = styled(Column)`
    align-items: center;
    justify-content: center;
    height: 80vh;
`;


const LoadingIcon = styled.img`
    height: 40px;
`;

const LoadingScreen = () => {
    return <LoadingScreenWrapper>
        <LoadingIcon src={loadingIcon} />
    </LoadingScreenWrapper>
}

export const OnboardingForm = ({ 
  fields, 
  footer = <div />, 
  setShareBannerVisible,
  onEndForm
}) => {

  const { userData, loading } = useSelector((state) => state.user);
  const hash = useLocation().hash;
  const navigate = useNavigate();
  const step = hash ? parseInt(hash.replace('#', '')) : 0;
  const setStep = (step) => navigate(`#${step}`);
  const originalFields = fields

  const isPreFilledField = (field) => {
    if (!userData?.required_data) return true;
    const existingData = userData?.required_data[field.name];
    return !(existingData !== null && existingData !== undefined && field.askOnlyOnce);
  }
  fields = fields.filter(isPreFilledField);

  const field = fields[step];
  const [isValid, setIsValid] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fieldAnswers, setFieldAnswers] = useState({})
  const [prefilledAnswers, setPrefilledAnswers] = useState({})
  const [submitButtonText, setSubmitButtonText] = useState('Continue')
  const [submitting, setSubmitting] = useState(false);

  const myDivRef = useRef(null);
  const { currentUser } = useAuth();
  const dispatch = useDispatch();
  useFirebaseWarningRemove();

  useEffect(() => {
    if (field.condition) {
      if (field.condition && field.name in fieldAnswers) {
        navigate(-1);
      }
    }
  }, [field])

  useEffect(() => {
    const fieldsToErase = fields.slice(step + 1);
    const fieldNames = fieldsToErase.map(field => field.name);
    setFieldAnswers(previous => {
      fieldNames.forEach(field => {
        delete previous[field]
      })
      return previous;
    })
  }, [step])
  
  useEffect(() => {
      if (currentUser) {
          dispatch(fetchUserData())
      };
  }, [currentUser])

  useEffect(() => {
    if (sessionStorage.getItem('onboardingFormAnswers')) {
      const answers = sessionStorage.getItem('onboardingFormAnswers');
      setFieldAnswers(JSON.parse(answers));
    };
  }, [])

  useEffect(() => {
    setIsValid(false);
  }, [step])

  useEffect(() => {
    if (userData?.first_name) {
      autoFillAnswers(userData)
    }
  }, [userData])

  const autoFillAnswers = () => {
    const defaultAnswers = originalFields.reduce((acc, field) => {
      if (!isPreFilledField) return acc
      let existingData = userData?.required_data[field.name];
      if (field.name === 'date_of_birth') {
        existingData = moment(existingData, 'YYYY-MM-DD').format(getDateFormat(MOMENT_FORMAT))
      }
      return {
        ...acc,
        [field.name]: existingData
      }
    }, {})
    setPrefilledAnswers(defaultAnswers)
  }
  
  const onUpdate = (isValid, fieldUpdate) => {
    let newAnswers;
    setFieldAnswers(previous => {
      newAnswers = {
        ...previous,
        ...fieldUpdate
      }
      return newAnswers;
    })
    setIsValid(isValid)
    if (isValid && field.type === 'selector') {
      onNext(newAnswers);
    }
    return newAnswers;
  }

  const onNext = async (answers) => {
    if (!answers) answers = fieldAnswers;
    if (hasNextQuestion()) {
      nextQuestion(answers)
      return
    }
    let data = {...prefilledAnswers, ...answers};
    setSubmitting(true);
    await onEndForm(data);
    setSubmitting(false);
  }
  
  const nextQuestion = (answers) => {
    const nextField = fields[step + 1];
    let stepCount = 1;
    if (!nextField.condition) return setStep(step + stepCount)

    if (nextField?.condition && nextField.condition(answers)) {
      console.log('can pass');
    } else {
      setFieldAnswers((prev) => ({
        ...answers,
        [nextField.name]: nextField.defaultAnswer(fieldAnswers)
      }))
      stepCount = 2
    }

    setStep(step + stepCount)
  }

  const previousQuestion = () => {
    navigate(-1);
  }

  const calculateProgress = () => {
    return (step / (fields.length - 1)) * 100
  }

  useEffect(() => {
    setProgress(calculateProgress())
    if (step < fields.length - 1) {
      setSubmitButtonText('Continue')
      return
    }
    setSubmitButtonText('Submit')
  }, [step])

  const onPreviousQuestion = () => {
    previousQuestion()
  }

  const hasPreviousQuestion = () => step > 0

  const hasNextQuestion = () => step < fields.length - 1;

  const { keyboardOpen, keyboardHeight } = useKeyboardOpen();

  if (loading) return <LoadingScreen />
  
  return (
    <>
      <AgePredictNavbar />
      <ProgressBarWrapper>
        <ProgressBar percent={progress} width='100%' background="linear-gradient(271deg, #4F59F0 -3.59%, #B5EDFF 92.5%)" />
      </ProgressBarWrapper>
      <OnboardingFormWrapper keyboardHeight={keyboardHeight} keyboardOpen={keyboardOpen}>
          <FieldHeaderMobile 
            onPreviousQuestion={onPreviousQuestion}
            hasPreviousQuestion={hasPreviousQuestion}
            setShareBannerVisible={setShareBannerVisible}
          />
          <Column style={{ flex: 1, alignItems: 'center', justifyContent: 'center', gap: 20 }}>
          <QuestionWrapper>
            <Field 
              key={field.question}
              field={field}
              fieldAnswers={fieldAnswers}
              onUpdate={onUpdate}
              onPreviousQuestion={onPreviousQuestion}
              hasPreviousQuestion={hasPreviousQuestion}
              hasNextQuestion={hasNextQuestion}
              myDivRef={myDivRef}
              submitAction={onNext}
            />
            
          </QuestionWrapper>
          {field?.type !== 'selector' &&
              <SubmitButton
                disabled={!isValid || submitting}
                onClick={() => onNext()}>
                {submitButtonText}
                <Icon src={arrowRight} />
              </SubmitButton>}
          </Column>
            
      {footer}
      </OnboardingFormWrapper>
    </>
  )
}