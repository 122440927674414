import React from 'react';
import styled from 'styled-components';
import { Row } from './defaults';
import { MultibrandLogo } from './multibrand-logo';

const Logo = styled(MultibrandLogo)`
    height: 56px;
    width: 120px;

    @media (max-width: 768px) {
        height: 43px;
        width: 100px;
    }
`;

const Wrapper = styled(Row)`
    border-bottom: 1px solid #E0E0E0;
    align-items: center;
    padding: 16px;
    background: white;

    @media (max-width: 768px) {
        padding-left: 15px;
        padding-right: 15px;
    }
`;

export const AuthHeader = () => {

    return <Wrapper >
        <Logo />
    </Wrapper>

}
