import { useNavigate } from "react-router-dom";
import { OnboardingForm } from "../../components/onboarding-form";
import { ONBOARDING_FORM } from "../../constants/onboarding-form";
import api from "../../utils/api";



export const SignUpOnboardingDetails = () => {

    const navigate = useNavigate();

    const onEndForm = async (responses) => {
        await api.post('/user/demographic', responses);
        navigate('/dashboard')
    }

    return <OnboardingForm
        fields={ONBOARDING_FORM}
        onEndForm={onEndForm} />

}