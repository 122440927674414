import { KitHeader } from "../components/kit-header"
import styled from "styled-components"
import { Link } from "react-router-dom";
import { Row } from "../components/defaults";
import { MultibrandLogo } from "../components/multibrand-logo";
import { useWhiteLabelData } from "../utils/white-label";


const Header = styled(Row)`
    height: 88px;
    background: white;
    align-items: center;
    padding-left: 24px;
    border-bottom: 1px solid rgba(0,0,0,.1);

    img {
        height: 64px;
    }

    @media (max-width: 768px) {
        height: 72px;
        
        img {
            height: 40px;
        }
    }

`

const HeaderNotFound = () => {
  return (
    <Header>
        <Link to="https://trumelabs.com/">
        <MultibrandLogo className='w-[120px] h-[50px]' />
        </Link>
    </Header>
  )
}


export const NotFound = () => {

    const { loading, data } = useWhiteLabelData();

    return <div className="flex h-screen flex-col">
        <HeaderNotFound />
        <div className='flex flex-1 flex-col items-center justify-center'>
            <div style={{ color: '#051F73' }} className='text-2xl'>Not Found</div>
            <div className=''>This page was not found. Please go back to our <a 
                style={{ fontWeight: 800 }} 
                className="underline cursor-pointer" 
                href={data.valid_domain ? data.subdomain : 'https://trumelabs.com'}>homepage</a>.</div>
        </div>
    </div>


}