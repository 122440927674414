/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import api from '../../utils/api'
import { useNavigate } from 'react-router-dom';
import useAuth from '../../utils/useAuth';
import { useDispatch } from 'react-redux';
import { clear } from '../../redux/reducer/registration';
import { AuthBox } from '../auth-box';
import { 
    Error, 
    Form, 
    SubmitButton, 
    Subtitle, 
    Title,
    Input,
    ExtraLink
} from '../../components/auth-components';


export const BarcodeInputBox = ({onClickGoToDashboard, showSignIn, ...props}) => {

    const [barcode, setBarcode] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const ref = useRef();

    useEffect(() => {
        ref?.current?.focus();
    },  []) 

    const submit = async () => {
        if (loading || !barcode) return;
        setLoading(true)
        setError('');

        try {
            const result = await api.post('/kit', { barcode });
            if (!result.data?.success) throw new Error('Error')
            const nextUrl = !currentUser ? `/sign-up/kit/${barcode}` : `/dashboard/register-kit/${barcode}#0`
            navigate(nextUrl)
            
        } catch (err) {
            console.log('e', err)
            setError('Kit number was not found');
        }
        setLoading(false);
    }

    
    
    return <Form>
        <Title>Register Your Kit</Title>
        <Subtitle>Start by registering your unique kit number.</Subtitle>
        <Input 
        value={barcode}
        nextAction={submit}
        style={{ marginTop: 30, marginBottom: 20 }}
        onChange={(e) => setBarcode(e.target.value)} 
        placeholder='Enter your kit number'></Input>
        {error && <Error>{error}</Error>}
        <SubmitButton disabled={loading} onClick={() => submit()}>
            {loading ? <div>Checking your Kit</div> : <div>Submit</div>}
        </SubmitButton>
    </Form>
}


export const BarcodeInput = (props) => {

    
    return <AuthBox>
        <BarcodeInputBox {...props} />
    </AuthBox>   
}
