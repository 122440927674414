export const moveToNextFocusable = (parent, event, onEnd, { selector = 'input, select, textarea' }) => {
    if (parent) {
        const focusable = Array.from(
            parent.querySelectorAll(selector)
        ).filter(el => !el.disabled && el.tabIndex >= 0); // Find all focusable elements
        console.log(focusable)
        const index = focusable.indexOf(event.target); // Get the current element's index
        if (index >= 0 && index < focusable.length - 1) {
            focusable[index + 1].focus();
        } else if (index === focusable.length - 1) {
            onEnd();
        }
    }
}