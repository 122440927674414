import styled from "styled-components"
import bundleImage from '../../images/bundle.png';

const BundleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 55px;

    @media (max-width: 768px) {
      flex-direction: column;
    }
`

const BundleCard = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    border: 1px solid #E2E2FF;
    background: #FFF;
    width: ${props => props.width};
    height: 463px;
    @media (max-width: 768px) {
      display: none;
    }
`

const BundleImage = styled.img`
    object-fit: cover;
    height: 100%;
    width: 100%;
`

const BundleFooter = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 32px;
    border-top: 1px solid #E2E2FF;
`

const BundleName = styled.span`
    color: #000;
    font-family: 'Satoshi-Regular';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.208px;
`

const BundleContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`

const BundleTitle = styled.h1`
    color: #051F73;
    font-family: 'Satoshi-Medium';
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`

const BundleDescription = styled.p`
    color: #5B637D;
    font-family: 'Satoshi-Medium';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.208px;
`

const BundleItems = styled.ul`
    display: flex;
    flex-direction: column;
    padding: 0 24px;
`

const BundleItem = styled.li`
    color: #1C3B9F;
    font-family: 'Satoshi-Medium';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 190%;
    letter-spacing: -0.208px;
    list-style-type: disc;
`

const BundleButton = styled.button`
    display: inline-flex;
    padding: 6.5px 20px 10.5px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #5856D6;
    color: #fff;
    border-radius: 24px;
    width: fit-content;
`

export const Bundle = ({ supplementsList, supplementsLink }) => {

    return <BundleWrapper>
        <BundleCard>
            <BundleImage src={bundleImage} />
            <BundleFooter>
                <BundleName>Personalized Supplement Bundle.</BundleName>
            </BundleFooter>
        </BundleCard>
        <BundleContent>
            <BundleTitle>Optimize Your Health with a Personalized Supplement Bundle</BundleTitle>
            <BundleDescription>Based on your test results, we’ve curated a powerful supplement bundle designed to support your overall well-being, energy, and longevity. Each ingredient works synergistically to target key areas of health: </BundleDescription>
            <BundleItems>
                {supplementsList.map(supplement => (
                    <BundleItem>{supplement.name}</BundleItem>
                ))}
            </BundleItems>
            <BundleDescription>This combination is tailored to address your unique health needs, empowering you to take actionable steps toward optimizing your wellness and longevity.</BundleDescription>
            <a
                target='_blank' rel='noreferrer' 
                href={supplementsLink}
                style={{ display: 'flex', flexDirection: 'column', textDecoration: 'none'}}
            >       
                <BundleButton>Add to Cart</BundleButton>
            </a>
        </BundleContent>
    </BundleWrapper>
}