import styled from 'styled-components'


export const KitSubmissionTutorialWrapper = styled.div`
  height: calc(100dvh - 90px);
  overflow: auto;
  padding: 0px;
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
  align-items: center;
  width: 100%;

   @media (max-width: 768px) {
      height: 100%;
      flex: none;
   }
`

export const TutorialHomePageWrapper = styled.div`
  background: #0E112F;
  width: 100%;
  height: calc(100dvh - 90px);
  padding: 64px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;

  @media (max-width: 768px) {
    height: 100%;
    width: 100%;
    padding: 64px 0px;
    justify-content: start;
  }
`

export const TutorialTitle = styled.h1`
  color: #FFF;
  text-align: center;
  font-family: 'Satoshi-Medium';
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 60%;
  @media (max-width: 768px) {
    width: 80%;
    text-align: center;
  }
`

export const TutorialSubtitle = styled.h2`
  color: #C9C9C9;
  text-align: center;
  font-family: 'Satoshi-Medium';
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const TutorialHomePageStep = styled.div`
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(42px);
  height: 100px;
  width: 50%;
  display: grid;
  grid-template-columns: 50px 150px auto;
  align-items: center;
  padding: 20px 40px;
  gap: 32px;

  @media (max-width: 768px) {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: start;
    height: auto;
    width: 80%;
  }
`

export const NextStepButton = styled.button`
  display: flex;
  width: fit-content;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 140px;
  background: #4D56F2;
  color: #fff;
`

export const HomePageStepText = styled.span`
  color: #FFF;
  font-family: 'Satoshi-Medium';
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 28.5px */
  letter-spacing: -0.38px;
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const HomePageStepDescription = styled.span`
  color: #D8E1FF;
  font-family: 'Satoshi-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.32px;
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const Icon = styled.img``




export const TutorialStepWrapper = styled.div`
  background: #fff;
  width: 50%;
  height: calc(100dvh - 90px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 42px;

  @media (max-width: 768px) {
    height: 100%;
    width: 90%;
    padding: 64px 0;
    justify-content: start;
  }
`

export const TutorialStepHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`

export const TutorialStepName = styled.div`
  color: #051F73;
  font-family: 'Satoshi-Medium';
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`

export const TutorialStepTitle = styled.h1`
  color: #051F73;
  font-family: 'Satoshi-Medium';
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const StepDescription = styled.p`
  color: #5B637D;
  font-family: 'Satoshi-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: -0.32px;
`

export const StepDescriptionColumn = styled.div`
  display: flex;
  flex-direction: row;
  gap: 28px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const StepIndicator = styled.div`
  display: inline-flex;
  height: 24px;
  padding: 13px 24px 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #F5F6FA;
  color: #051F73;
  text-align: center;
  font-family: 'Satoshi-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.208px;
  min-width: 108px;

  @media (max-width: 768px) {
    width: 50%;
  }
`

export const Anchor = styled.a`
  color: #4D56F2;
  font-family: 'Satoshi-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.32px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
`

export const StepDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`