import { useEffect, useState } from "react";
import styled from "styled-components"
import { brandName, logo, website } from "../utils/white-label";
import loadingIcon from '../images/3-dots-move-purple.svg';

const Logo = styled.img`


`;

export const MultibrandLogo = ({
    className = '',
    ...props
}) => {

    const [logoSrc, setLogoSrc] = useState();
    const [alt, setAlt] = useState();
    const [webpage, setWebpage] = useState();

    const fetch = async () =>  {
        const resource = await logo();
        const name = await brandName();
        const webpageRes = await website();
        setLogoSrc(resource);
        setAlt(name);
        setWebpage(webpageRes)
    }

    const goToWebpage = () => {
        if (webpage) {
            const formattedWebpage = webpage.includes('http') ? webpage : `https://${webpage}`;
            window.open(formattedWebpage, '__blank')
        };
    }

    useEffect(() => {
        if (!logoSrc) fetch()
    }, []);

    if (!logoSrc) return <div className={`${className} flex items-center justify-start`}>
        <img src={loadingIcon} className={`w-[25px] h-[25px]`} alt={'Loading'} {...props} />
    </div>

    return <Logo onClick={goToWebpage} src={logoSrc} alt={alt} className={`${className} cursor-pointer object-contain`} {...props} />


}