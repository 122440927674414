import { getDateFormat, MOMENT_FORMAT } from "./date";
import moment from 'moment';

export const convertToFeetInches = (cm) => {
    const totalFeet = cm / 30.48; // Convert cm to feet as a decimal
    const feet = Math.floor(totalFeet); // Extract the whole number of feet
    const inches = (totalFeet - feet) * 12; // Convert the remaining fraction of feet to inches
    return { feet, inches };
  }

export const convertKilosToPouds = (kg) => kg * 2.20462

  
export const normalizeUnitAnswers = (answers) => {
  let height_inches = answers.height_inches
  let height_feet = answers.height_feet
  let weight = answers.weight_pounds
  if (answers.height_centimeter) {
    const {
      feet,
      inches
    } = convertToFeetInches(answers.height_centimeter)
    height_inches = inches
    height_feet = feet
  }

  if (answers.weight_kilos) {
    weight = convertKilosToPouds(answers.weight_kilos)
  }

  if (answers.original_registered_at) {
    answers.original_registered_at = moment(answers.original_registered_at, getDateFormat(MOMENT_FORMAT)).toDate()
  }

  if (answers.date_of_birth) {
    answers.date_of_birth = moment(answers.date_of_birth, getDateFormat(MOMENT_FORMAT)).toDate()
    console.log(answers)
  }

  if (answers.demographic_data?.date_of_birth) {
    answers.demographic_data.date_of_birth = moment(answers.demographic_data.date_of_birth, getDateFormat(MOMENT_FORMAT)).toDate()
  }

  return {
    ...answers,
    height_inches,
    height_feet,
    weight
  }

}