import { useNavigate, useParams } from 'react-router-dom';
import { OnboardingForm } from '../components/onboarding-form.js';
import { KIT_REGISTRATION_FORM } from '../constants/kit-registration-form.js';
import api from '../utils/api.js';
import { normalizeUnitAnswers } from '../utils/units.js';

export const SignedInRegisterKit = () => {

    const navigate = useNavigate();
    const fields = KIT_REGISTRATION_FORM;
    const { barcode } = useParams();

    const onEndForm = async (data) => {
        try {
            const demographic_data = {};
            for (const field of fields) {
                if (field.demographicData) demographic_data[field.name] = data[field.name];
            }
            data = { demographic_data, barcode, ...data}
            data = normalizeUnitAnswers(data);
            const result = await api.post('/kit/register', data);
            if (result.data?.age_results_ready) navigate('/registration/success?age_available=' + result.data?.age_results_ready)
            else if (result.data?.created_report) navigate('/registration/success?report_available=' + result.data?.created_report)
            else result.data?.kit_type == 'Folate' ? navigate('/kit-instructions/dna-methylation') : navigate('/kit-instructions/biological-age-test')
        } catch (err) {
            throw new Error(err.message);
        }
    }


    return (<div>
      <OnboardingForm 
      fields={fields}
      onEndForm={onEndForm} />
    </div>
  )
}