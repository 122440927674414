/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Column, Row } from '../components/defaults';
import '../images/eye.svg';
import styled from 'styled-components';
import eye from '../images/eye.svg'
import { useState } from 'react';
const breakpoint = '1000px'

export const Form = styled(Column)`
    background: white;
    max-width: 375px;
    padding: 56px;
    padding-top: 20px;
    padding-bottom: 10px;

    @media (max-width: ${breakpoint}) {
        max-width: initial;
        padding: 20px;
    }
`;

export const Title = styled.div`
    font-family: 'Satoshi-Bold';
    font-size: 41.47px;
    font-weight: 700;
    line-height: 45.62px;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 16px;
    color: rgba(15, 23, 42, 1);

    @media (max-width: ${breakpoint}) {
        text-align: center;
    }
`;

export const Subtitle = styled.div`
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 25.6px;
    text-align: left;
    color: rgba(71, 85, 105, 1);

    @media (max-width: ${breakpoint}) {
        text-align: center;
    }
`;

const InputComponentWrapper = styled(Row)` 
    height: 60px;
    position: relative;
    box-sizing: border-box;
    background: #F1F5F9;
    border-radius: 56px;
    outline: none;
    border: none;
    overflow: hidden;
    

    @media (max-width: ${breakpoint}) {
        width: initial;
    }

`;

export const InputComponent = styled.input`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    outline: none;
    border: none;
    width: 100%;
    height: 100%;
    padding: 0 20px;

    background: transparent;

`;

const EyeButton = styled.img`
  opacity: ${props => props.active ? '1' : '0.3'};
  cursor: pointer;
  height: 20px;
  position: absolute;
  right: 15px;
  top: 20px;
`;

export const Input = React.forwardRef(
    ({ nextRef, type, nextAction, ...props}, ref) => {

    const [innerType, setInnerType] = useState(type);

    const onClickEye = () => {
        setInnerType((t) => t === 'password' ? 'text' : 'password')
    }

    console.log('i', innerType)
    

    const onKeyDown = (e) => {
        if (e.key === 'Enter' && nextRef) {
            nextRef.current.focus();
        }
        if (e.key === 'Enter' && nextAction) {
            nextAction()
        }
    }

    return <InputComponentWrapper {...props}>
        <InputComponent
        onKeyDown={onKeyDown}
        ref={ref}
        {...props} 
        style={{}}
        type={innerType} />
        {type === 'password' && <EyeButton src={eye} onClick={onClickEye} />}
    </InputComponentWrapper>

})


export const SubmitButton = styled.button`
    justify-content: center;
    align-items: center;
    padding: 24px 48px;
    gap: 8px;
    box-sizing: border-box;
    height: 70px;
    background: linear-gradient(253.55deg, #5EEAD4 21.18%, #0284C7 94.82%);
    border-radius: 100px;
    display: flex;
    align-items: center;
    outline: none;
    border: none;
    cursor: pointer;

    &:disabled {
        opacity: 0.8;
        cursor: initial;
    }

    * {
        font-family: Inter;
        font-size: 18px;
        font-weight: 700;
        line-height: 32px;
        text-align: left;
        color: rgba(30, 41, 59, 1);
    }

`;

export const Error = styled.div`
    color: red;
    font-family: Inter;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: 500;
    
    @media (max-width: ${breakpoint}) {
        text-align: center;
    }

`;

export const ExtraLink = styled.a`
    margin-top: 15px;
    color: rgba(0,0,0,.5);
    text-decoration: underline;
    cursor: pointer;

    @media (max-width: ${breakpoint}) {
        text-align: center;
    }
`;
