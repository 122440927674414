/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Column, Container } from '../components/defaults';
import styled from 'styled-components';
import mainImage from '../images/entry-image.png'
import entryGraph1 from '../images/entry-graph-1.svg';
import entryGraph2 from '../images/entry-graph-2.svg';
import { logo } from '../utils/white-label';
import { MultibrandLogo } from '../components/multibrand-logo';

const BACKGROUND = 'radial-gradient(78.01% 585.74% at 54.44% 43.57%, #09112A 22%, #18357E 100%)';

const breakpoint = '1000px'

const BoxContainer = styled.div`
    border-radius: 32px;
    overflow: hidden;
    display:flex;
    flex-direction: row;
    max-height: 600px;
    background: white;
    position: relative;
    border: 10px solid white;

    @media (max-width: ${breakpoint}) {
        flex-direction: column;
        max-height: initial;
        margin-top: 25px;
        margin-bottom: 25px;
        width: 400px;
    }

    @media (max-width: 600px) {
        
        width: 80vw;
    }
`;

const MainImage = styled.img`
    border-radius: 28px;
    max-width: 408px;
    overflow: hidden;
    object-fit: cover;

    @media (max-width: ${breakpoint}) {
        height: 320px;
        max-width: initial;
        border-radius: 24px;
        object-position: 00px -60px;
        object-fit: cover;
    }

    @media (max-width: 768px) {
       display: none;
    }
`;

const Logo = styled(MultibrandLogo)`
    justify-self: center;
    width: 150px;
    height: 100px;
    object-fit: contain;
    margin-left: 56px;

    @media (max-width: ${breakpoint}) {
        align-self: center;
        margin-left: 0px;
        margin-top: 40px;
    }
`;

const EntryGraph1 = styled.img`
    position: absolute;
    top: 100px;
    left: -85px;

    @media (max-width: ${breakpoint}) {
        left: 20px;
        top: 220px;
    }

    @media (max-width: 768px) {
       display: none;
    }
`;

const EntryGraph2 = styled.img`
    position: absolute;
    top: 290px;
    left: -120px;

    @media (max-width: ${breakpoint}) {
        display: none;
    }

    @media (max-width: 768px) {
       display: none;
    }
`;

const Box = ({ children }) => {
    return <BoxContainer>
        {children}
    </BoxContainer>
}

const containerStyle = { 
    background: BACKGROUND, 
    alignItems: 'center', 
    justifyContent: 'center' 
}

export const AuthBox = ({ children }) => {

    
    return <Container style={containerStyle}>
        <div style={{ position: 'relative'}}>
            <Box>
                <MainImage src={mainImage} alt='' />
                <Column style={{ alignSelf: 'center'}}>
                    <Logo />
                    {children}
                </Column>
            </Box>
            <EntryGraph1 src={entryGraph1} alt='' />
            <EntryGraph2 src={entryGraph2} alt='' />
        </div>
    </Container>    
}
